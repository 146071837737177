import { Badge, Button, Label, TextInput } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import { ArrowBack, Upload } from "../../layouts/icons";
import { Link } from "react-router-dom";
import { WriteUserData } from "../../services/firebaseActions/uploaderActions";
import { FileFormat } from "../../utilities/fileUploader/renameFile";
import { StorageContext } from "./uploadSection";
import {
  C_DIVIDEND_FEED,
  C_GENERAL,
  C_LEGAL_DOCUMENTS,
  C_TRADING_HOURS,
} from "../../constants/types";
import { StatusContext } from "../../context/statusProvider";
import { ResponseContext } from "../../context/responseProvider";
import { UPLOAD } from "../../routes/routes";

export const UploadForm = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [category, setCategory] = useState(props.formType);
  const [userId, setUserId] = useState("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDiableBtn] = useState(false);
  const [error, setError] = useState("");
  const { legalCategory } = useContext(StorageContext);
  const { handleApi } = useContext(StatusContext);
  const { response, setResponse } = useContext(ResponseContext);

  const formRef = useRef(0);

  const handleFileInput = (e) => {
    if (e.target.files[0] != null) {
      if (category !== C_GENERAL) {
        const formater = FileFormat(e.target.files[0], category);
        setSelectedFile(formater);
        setFileName(formater.name);
      } else {
        setSelectedFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //write data to rtdb
    WriteUserData(
      fileName,
      category,
      userId,
      setIsLoading,
      setResponse,
      formRef,
      legalCategory,
      handleApi,
      selectedFile,
      setFileName,
      setSelectedFile,
      setError
    );
  };

  useEffect(() => {
    setCategory(props.formType);
  }, [category, props.formType]);

  useEffect(() => {
    const isLegalCategoryRequired = category === C_LEGAL_DOCUMENTS;
    const isFormIncomplete =
      !selectedFile ||
      !userId ||
      !category ||
      (isLegalCategoryRequired && !legalCategory);
    setDiableBtn(isFormIncomplete);
  }, [selectedFile, userId, category, legalCategory]);

  return (
    <div className="st-2 w-full sm:w-2/3">
      <div className="flex h-full flex-col justify-center gap-4 p-6 pl-2">
        <div className="w-max">
          {response ? (
            <Badge color="success">Updated successfully!</Badge>
          ) : !response && response !== null ? (
            <Badge color="failure">Upload failed..</Badge>
          ) : (
            ""
          )}
        </div>
        <div className="mb-2 block">
          <Label value="File Name:" />
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {category === C_LEGAL_DOCUMENTS && legalCategory !== "" && (
              <>
                Uploading to <strong>{legalCategory}</strong> -
              </>
            )}{" "}
            {fileName !== "" ? <strong>{fileName}</strong> : "Choose file"}
          </p>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="flex flex-col gap-4 "
          ref={formRef}
        >
          <div>
            <div className="mb-2 block">
              <Label htmlFor="uploaderId" value="Confirm Email" />
            </div>
            <TextInput
              id="email1"
              type="email"
              placeholder="name@gomarkets.com"
              onChange={(e) => setUserId(e.target.value)}
              required={true}
              helperText="Email address you used to sign in"
              pattern={localStorage.getItem("userEmail")}
            />
          </div>

          <div id="fileUpload" className="mt-10">
            <span className="text-red-500 text-sm">
              {error !== "" && error}
            </span>
            <div className="flex justify-center items-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {category === C_DIVIDEND_FEED ||
                    category === C_TRADING_HOURS
                      ? "Accepted format: csv"
                      : category === C_GENERAL
                      ? "Accepted formats: pdf, doc, docx, csv, txt, zip, rar, png, jpg, apk"
                      : "Accepted formats: pdf, doc, docx, csv, txt, zip, rar, png, jpg"}
                  </p>
                  {fileName !== "" && (
                    <div className="inline-block w-[263.98px] mt-2 font-bold text-xs">
                      <span className="truncate block text-xs text-primary dark:text-gray-400">
                        <span className="text-gray-500 dark:text-gray-400">
                          Uploading:
                        </span>{" "}
                        {fileName}
                      </span>
                    </div>
                  )}
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="opacity-0 absolute  w-[263.99px] h-[256px]"
                  onChange={handleFileInput}
                  accept={
                    category === C_DIVIDEND_FEED || category === C_TRADING_HOURS
                      ? ".csv"
                      : category === C_GENERAL
                      ? "Accepted formats: pdf, doc, docx, csv, txt, zip, rar, png, jpg, apk"
                      : "Accepted formats: pdf, doc, docx, csv, txt, zip, rar, png, jpg"
                  }
                  required={true}
                />
              </label>
            </div>
          </div>
          <div className="flex mt-8 theme-variant items-center justify-between ">
            <Link
              to={`/${UPLOAD}`}
              className="inline-flex items-center text-center"
            >
              <ArrowBack /> Return
            </Link>

            {isLoading ? (
              <Upload />
            ) : (
              <Button type="submit" disabled={disableBtn}>
                Upload
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
